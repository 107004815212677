import { render } from "./loginNurse.vue?vue&type=template&id=381bf2b4&scoped=true"
import script from "./loginNurse.vue?vue&type=script&lang=js"
export * from "./loginNurse.vue?vue&type=script&lang=js"

import "./loginNurse.vue?vue&type=style&index=0&id=381bf2b4&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-381bf2b4"
/* hot reload */
if (module.hot) {
  script.__hmrId = "381bf2b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('381bf2b4', script)) {
    api.reload('381bf2b4', script)
  }
  
  module.hot.accept("./loginNurse.vue?vue&type=template&id=381bf2b4&scoped=true", () => {
    api.rerender('381bf2b4', render)
  })

}

script.__file = "src/views/login/loginNurse.vue"

export default script